import React from "react"
import ImageTextTile from "../components/imageTextTile"
import CommunityTile from "../components/communityTile"
import EventTile from "../components/eventTile"
import JobTile from "../components/jobTile"
import PartnerEventTile from "../components/partnerEventTile"
import { Link } from "gatsby"

const Destination = ({children, entry, linkGenerated, internalLink, link}) => {
    if(entry.frontmatter.type === "job") {
        const filterType = entry.frontmatter.filterType
        const filterValue = entry.frontmatter.filterValue
        let jobsLink = "/jobwall/jobs/"
        if(filterType && filterType !== "" && filterValue && filterValue !== "") {
            jobsLink = jobsLink + "?" + filterType + "=" + filterValue
        }   
        return <Link to={jobsLink}>{children}</Link>
    }
    if(linkGenerated === true) {
      return(
        <Link to={`/details${entry.fields.slug}`}>{children}</Link>
      )
    } else if(link && !internalLink && link !== "") {
      return(
        <a href={link}>{children}</a>
      )
    } else if(link && internalLink && link !== "") {
        return(<Link to={link}>{children}</Link>)
    } else {
      return (
        <div>{children}</div>
      )
    }
}

export function getTile(entry, prio, partnerEventImage, type, bigFont, padding, isSmall, isBig) {
        let content
        if(entry.frontmatter.type === "event") {
          content = 
                <EventTile tag="Event" heading={entry.frontmatter.title} date={entry.frontmatter.date} 
                            image={entry.frontmatter.image.childImageSharp.fluid} speaker={entry.frontmatter.speaker}
                            company={entry.frontmatter.company} prio={prio} isSmall={isSmall} isBig={isBig}/>
          
        } else if(entry.frontmatter.type === "partnerEvent") {
            content = 
                <PartnerEventTile tag="Event" heading={entry.frontmatter.title} date={entry.frontmatter.date} 
                            image={entry.frontmatter.image.childImageSharp.fluid} speaker={entry.frontmatter.speaker}
                            company={entry.frontmatter.company} prio={prio}
                            partnerEventImage={partnerEventImage} html={entry.html} isSmall={isSmall} isBig={isBig}/>
        } else if(entry.frontmatter.type === "community") {
          content = <CommunityTile tag="Community" heading={entry.frontmatter.title} html={entry.html} date={entry.frontmatter.date}
                            prio={prio} isSmall={isSmall} isBig={isBig}/>
        } else if(entry.frontmatter.type === "job") {
            content = <JobTile type={type} text={entry.frontmatter.title} padding={padding} isSmall={isSmall}
                        logoType={entry.frontmatter.jobIcon} bigFont={bigFont} prio={prio}/>
              
          } else {
            content = <ImageTextTile image={entry.frontmatter.image.childImageSharp.fluid} heading={entry.frontmatter.title}
                            prio={prio} subheading={entry.frontmatter.subtitle} isSmall={isSmall} isBig={isBig}/>
        }
      

      return (
          <Destination linkGenerated={entry.frontmatter.generateLink} link={entry.frontmatter.link} entry={entry} internalLink={entry.frontmatter.internalLink}>{content}</Destination>
      )
}