import React from "react"
import BackgroundImage from "gatsby-background-image"
import { formatDatetime } from "../helpers/formatter"
import * as eventTileStyles from "./eventTile.module.scss"

function EventTile(props) {
    const {image, heading, tag, date, speaker, company, prio, isSmall, isBig} = props
    const formattedDate = formatDatetime(date)
    return(
        <div className={`${eventTileStyles.imageBox} ${isSmall ? eventTileStyles.imageSmall: ''} ${isBig ? eventTileStyles.imageTall: ''} 
        ${!isSmall && !isBig ? eventTileStyles.imageAuto: ''} ${prio===1 ? eventTileStyles.prio1 : ""} ${prio===2 ? eventTileStyles.prio2 : ""} ${prio===3 ? eventTileStyles.prio3 : ""}`}>
            <BackgroundImage
                className={`${eventTileStyles.image}`}
                fluid={image}>
                <div className={eventTileStyles.content}>
                    <p><span className={eventTileStyles.tag}>{tag}</span> &#183; {formattedDate}</p>
                    <div className={eventTileStyles.contentBox}>
                        <h3>{heading}</h3>
                        <p>{speaker} {company}</p>
                    </div>
                </div>
              </BackgroundImage>
        </div>
    )
}

export default EventTile