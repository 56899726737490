import React from "react"
import Img from "gatsby-image"
import { formatDatetime } from "../helpers/formatter"
import * as eventTileStyles from "./partnerEventTile.module.scss"

function PartnerEventTile(props) {
    const {image, heading, date, prio, partnerEventImage, html, isSmall, isBig} = props
    const formattedDate = formatDatetime(date)
    return(
        <div className={`${isSmall ? eventTileStyles.eventSmall: ""} ${isBig ? eventTileStyles.eventBig : ""} ${!isSmall && !isBig ? eventTileStyles.eventAuto : ""}  ${eventTileStyles.text} 
                        ${prio===1 ? eventTileStyles.prio1 : ""} ${prio===2 ? eventTileStyles.prio2 : ""} ${prio===3 ? eventTileStyles.prio3 : ""} ${eventTileStyles.contentBox}`}>
                <div className={eventTileStyles.image}>
                    <div className={eventTileStyles.lines}>
                        <div className={eventTileStyles.line1}/>
                        <div className={eventTileStyles.line2}/>
                    </div>
                    <div className={eventTileStyles.imageWrapper}>
                        <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} backgroundColor={true}
                                    fluid={image} alt=""/>
                    </div>
                    <div className={eventTileStyles.lines}>
                        <div className={eventTileStyles.line3}></div>
                        <div className={eventTileStyles.line4}></div>
                    </div>
                </div>
                <div className={eventTileStyles.content}>
                    <div className={eventTileStyles.top}>
                        <div className={eventTileStyles.date}><p>{formattedDate}</p></div>
                        <div className={eventTileStyles.partnerEventImageWrapper}>
                            <Img style={{ height: "13px", width: "100px"}} 
                                imgStyle={{ objectFit: "contain" }} 
                                fixed={partnerEventImage.childImageSharp.fixed} alt=""/>
                        </div>
                    </div>
                    <div className={eventTileStyles.bottom}>
                        <div className={eventTileStyles.bottomContentBox}>
                            <h3>{heading}</h3>
                            <div dangerouslySetInnerHTML={{ __html: html }}></div>
                        </div>
                    </div>
                </div> 
        </div>
    )
}

export default PartnerEventTile