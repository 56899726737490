import React from "react"
import PostItTile from "./postItTile"
import PlaceLogo from "../../assets/pin-jobs.svg"
import KofferLogo from "../../assets/koffer-jobs.svg"
import DukeLogo from "../../assets/duke.svg"

import * as jobTileStyles from "./jobTile.module.scss" 

class JobTile extends React.Component {

  getLogo(logoType, type, isSmall) {
    let logo
    if(logoType=="location") {
      logo = <PlaceLogo />
    } else if(logoType=="type") {
      logo = <KofferLogo />
    } else {
      logo = <DukeLogo />
    }
    return <div className={`${jobTileStyles.logo} ${type==1?jobTileStyles.darkLogo: ""} 
          ${type==2?jobTileStyles.mediumLogo: ""} ${type==3?jobTileStyles.brightLogo: ""} 
          ${isSmall?jobTileStyles.littleLogo: jobTileStyles.bigLogo}`} >{logo}</div>
  }
  
  render() {
    //type 1 dark, type 2 medium, type 3 bright
    const {type, isSmall, text, prio, jobsIn, number, place, description, logoType, bigFont} = this.props
    return (
        <div className={`${isSmall ? jobTileStyles.small: jobTileStyles.tall} ${type===1 ? jobTileStyles.dark: ""} 
                        ${type === 2 ? jobTileStyles.medium: ""} ${type === 3 ? jobTileStyles.bright: ""} 
                        ${prio===1 ? jobTileStyles.prio1 : ""} ${prio===2 || prio===3 ? jobTileStyles.prio2 : ""} ${prio===3 ? jobTileStyles.prio3 : ""}`}>
                <PostItTile type={type}>
                  <div className={jobTileStyles.wrapper}>
                    {this.getLogo(logoType, type, isSmall)}
                    <div className={`${jobTileStyles.content} ${bigFont ? jobTileStyles.bigFont : ""}`}>
                        {jobsIn && 
                          <div className={jobTileStyles.text}>
                            <h2 className={jobTileStyles.bold}>{number}</h2> <h2>{description}</h2> <h2 className={jobTileStyles.bold}>{place}</h2>
                          </div>
                        }
                        {!jobsIn && <h2>{text}</h2>}
                    </div>
                  </div>
                </PostItTile>
        </div>
    )
  }
}

export default JobTile
