import React from "react"
import { formatDate } from "../helpers/formatter"
import * as communityTileStyles from "./communityTile.module.scss"

function TextTile(props) {
    const {tag, date, heading, html,  prio, isSmall, isBig } = props
    const formattedDate = formatDate(date)
    return(
        <div className={`${communityTileStyles.textBox} ${communityTileStyles.text} ${isSmall ? communityTileStyles.textSmall: ""} ${isBig ? communityTileStyles.textTall: ""}  ${!isSmall && !isBig ? communityTileStyles.textAuto: ""} ${prio===1 ? communityTileStyles.prio1 : ""} ${prio===2 ? communityTileStyles.prio2 : ""} ${prio===3 ? communityTileStyles.prio3 : ""}`}>
            <div className={communityTileStyles.content}>
                <div className={communityTileStyles.contentBox}>
                    <h6><span className={communityTileStyles.tag}>{tag}</span> &#183; {formattedDate}</h6>
                    <h3>{heading}</h3>
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
            </div>
        </div>
    )
}

export default TextTile