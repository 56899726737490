import React from "react"

import * as postItTileStyles from "./postItTile.module.scss" 

class PostItTile extends React.Component {
  render() {
    const { children, type } = this.props
    return (
        <div className={`${postItTileStyles.postItNote} ${type===1 ? postItTileStyles.darkColor : ""} 
                          ${type===2 ? postItTileStyles.mediumColor : ""} ${type===3 ? postItTileStyles.lightColor : ""}
                          ${type===4 ? postItTileStyles.transparentColor : ""}`}>
           {children}
           {type === 4 &&
            <div>
              <div className={postItTileStyles.topLeft}>
                <div className={`${postItTileStyles.crossA}`}></div>
                <div className={postItTileStyles.crossB}></div>
              </div>
            </div>
           }
        </div>
    )
  }
}

export default PostItTile
